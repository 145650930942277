<template>
  <div class="ticket-detail-card">
    <div class="title">
      <font-awesome-icon :icon="ticketSource.icon" class="icon" />
      <div class="info">
        <div class="name">
          #{{ ticketDetail.ticket.id }} -
          {{ ticketDetail.user_detail.name || "-" }} ({{
            ticketDetail.user_detail.member_id || "-"
          }})
        </div>
        <ul>
          <li>{{ ticketSource.name }}</li>
          <li>
            {{ $moment(ticketDetail.ticket.created_time).fromNow() }}
            ({{
              $moment(ticketDetail.ticket.created_time).format(
                "DD/MM/YYYY HH:mm:ss"
              )
            }})
          </li>
        </ul>
      </div>
    </div>
    <hr />
    <div v-html="ticketDetail.ticket.ticket_detail"></div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    form: {
      required: false,
      type: Array,
    },
    ticketDetail: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    ticketSource() {
      const sourceField = this.form.find((el) => el.ticket_field_id === 2);
      const name = sourceField?.field_choice.find(
        (el) => el.id == this.ticketDetail.ticket.activity_source_id
      )?.name;

      return {
        icon: this.getActivityIcon(this.ticketDetail.ticket.activity_source_id),
        name: name || "Inbound Call",
      };
    },
  },

  methods: {
    getActivityIcon(id) {
      if (id == 33) return ["fab", "line"];
      else if (id == 34) return "phone-alt";
      else if (id == 154) return "mail-bulk";
      return "phone";
    },
  },
};
</script>

<style lang="scss" scoped>
.ticket-detail-card {
  background-color: white;
  padding: 16px;
  display: flex;
  gap: 8px;
  flex-direction: column;
  border-radius: 0.75rem;

  .title {
    display: flex;
    gap: 8px;
    color: #333333;
    font-size: 14px;
    align-items: center;
  }

  .icon {
    color: var(--primary-color);
    font-size: 30px;
  }

  .info {
    ul {
      font-size: 12px;
      color: #bababa;
      padding-left: 0;
      margin-bottom: 0;

      li {
        display: inline-flex;
        color: #bababa !important;
        gap: 4px;
        margin-right: 8px;
      }

      li::before {
        content: "•";
        padding-right: 8px;
        color: #a2a2a2;
      }
    }
  }

  hr {
    width: 100%;
    border: 0.5px solid #eeeeee;
    margin: 0;
  }
}
::v-deep p > img {
  width: 100%;
}
</style>
